.maincontainer {
  width: 100vw;
  height: 100%;
  background: var(--background-color);
  overflow-y: auto;
  overflow-x: hidden;
}

.mainholder {
  width: 100vw;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10vh;
  gap: 5vh;
}

.sectionfirst {
  width: 86vw;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 5vh 0;
}

.leftsection {
  padding-top: 15vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4vh;
}

.headingfirst {
  color: var(--main-text-color);
  font-size: 4vw;
  line-height: 8vh;
  font-family: "Garnett-Medium";
  font-weight: 800;
}

.gradienttext {
  background: var(--gradient-color);
  background-clip: text;
  color: transparent;
}

.howtoimage {
  width: 14vw;
}

.rightimage {
  padding-top: 10vh;
  width: 45vw;
  transform: scale(1);
  animation-name: imageglow;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes imageglow {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.04);
  }
}

.rightsection {
  background-image: url("../../assets/images/resources/gradientcircle.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 45vw;
  background-position: center;
}

.arrowdown {
  position: absolute;
  bottom: 10vh;
  left: 10vw;
  width: 1.4vw;
}

.sectiontwo {
  width: 80vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5vh;
}

.sectionhead {
  font-size: 2.6vw;
  font-family: "Garnett-Medium";
  background: var(--gradient-color);
  background-clip: text;
  color: transparent;
  line-height: 5vh;
}

.videoresholder {
  display: grid;
  column-gap: 2vw;
  row-gap: 5vh;
  grid-template-columns: auto auto auto;
}

.resholder {
  width: 26vw;
  border-radius: 10px;
  background: #1c1c1c;
}

.resholder:hover {
  cursor: pointer;
}

.restthumbnail {
  width: 26vw;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.reshead {
  color: var(--main-text-color);
  font-size: 1vw;
  padding: 2vh 1vw 0 1vw;
}

.resdate {
  padding: 1vh 1vw;
  color: #9c9c9c;
  font-size: 0.8vw;
}

.resdescription {
  color: #8c8c8c;
  padding: 1vh 1vw 4vh 1vw;
  font-size: 0.8vw;
}

.sectionthree {
  width: 80vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5vh;
  padding: 5vh;
}

.faqholder {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 2vw;
  row-gap: 2vw;
  background: rgba(70, 70, 70, 0.2);
  backdrop-filter: blur(25px);
  border-radius: 26px;
  padding: 4vh 2vw;
}

.accordion {
  background: rgba(70, 70, 70, 0.2) !important;
  backdrop-filter: blur(25px);
  width: 20vw;
  border-radius: 10px !important;
  padding: 1vh 1vw;
}

.accordiontype {
  color: var(--main-text-color);
}

.accordionicon {
  color: var(--main-text-color);
}

.sectionfour {
  width: 80vw;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 5vw;
  padding: 5vh 0;
}

.helpleft {
  width: 50vw;
  display: flex;
  align-items: flex-start;
  gap: 4vh;
  flex-direction: column;
}

.explain {
  font-size: 1.6vw;
  color: var(--main-text-color);
}

.readmorelink {
  color: var(--main-text-color);
  font-weight: 600;
  text-decoration: none;
}

.helpright {
  width: 45vw;
  padding: 3vw;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 5vh;
  background: #1c1c1c;
  border-radius: 10px;
}

.label {
  color: var(--main-text-color);
  font-family: "Garnett-Light";
}

.input {
  background: transparent;
  color: var(--main-text-color);
  width: 80%;
  padding: 1vh;
  border: none;
  border-bottom: 1px solid #c7c7d6;
}

.input:focus {
  outline: none;
}

.inputholder {
  width: 100%;
}

// For mobile screens
@media only screen and (max-width: 600px) {
  .sectionfirst {
    flex-direction: column-reverse;
    gap: 0;
  }

  .rightimage {
    width: 90vw;
  }

  .rightsection {
    width: 90vw;
    background-position: center;
    background-size: contain;
  }

  .leftsection {
    width: 90vw;
    padding-top: 5vh;
  }

  .headingfirst {
    font-size: 9vw;
    line-height: 5vh;
  }

  .howtoimage {
    width: 60vw;
  }

  .arrowdown {
    display: none;
  }

  .sectionhead {
    font-size: 7vw;
  }

  .videoresholder {
    grid-template-columns: auto;
  }

  .resholder {
    width: 90vw;
  }

  .restthumbnail {
    width: 90vw;
  }

  .reshead {
    font-size: 4vw;
    line-height: 3vh;
  }

  .resdate {
    font-size: 3vw;
  }

  .resdescription {
    font-size: 2.6vw;
    padding-bottom: 2vh;
    line-height: 2.4vh;
  }

  .accordion {
    width: 70vw;
    background: rgba(70, 70, 70, 0.2) !important;
    backdrop-filter: blur(25px);
  }

  .sectionfour {
    flex-direction: column;
    padding-top: 0;
  }

  .sectionhead {
    text-align: center;
    margin-top: 0;
    font-size: 10vw;
  }

  .helpleft {
    align-items: center;
    justify-content: center;
    width: 80vw;
    gap: 1vh;
  }

  .explain {
    font-size: 4vw;
    text-align: center;
    line-height: 3vh;
    font-family: "Garnett-Light";
    font-weight: 200;
  }

  .helpright {
    width: 70vw;
    padding: 5vw;
  }
}
