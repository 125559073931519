.individualholder {
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  background: var(--background-color);
}

.blogcontentholder {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15vh 0;
  flex-direction: column;
}

.blogholder {
  width: 55vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.BlogPublishedOn {
  font-size: 1vw;
  color: var(--text-color);
  padding-top: 2vh;
  width: 55vw;
  text-align: left;
}

.BlogHeading {
  font-size: 2.5vw;
  padding-top: 2vh;
  line-height: 6vh;
  background: black;
  color: var(--text-color);
  text-align: left;
}

.BlogImage {
  width: 55vw;
  padding-top: 4vh;
}

.BlogCategory {
  font-size: 1.4w;
  padding-top: 0;
  display: block;
  background: var(--gradient-color);
  background-clip: text;
  color: transparent;
  text-align: left;
  width: 55vw;
}

.BlogInfo {
  color: var(--grey-color);
  font-size: 1.4vw;
  padding-top: 3vh;
  line-height: 3.8vh;
  text-align: justify;
}

.SubHeading {
  font-size: 2vw;
  color: var(--text-color);
  padding-top: 5vh;
  text-align: left;
  width: 55vw;
}

.SubImage {
  width: 40vw;
  padding-top: 5vh;
}

.SubInfo {
  color: var(--grey-color);
  font-size: 1.4vw;
  padding-top: 3vh;
  line-height: 3.8vh;
  text-align: justify;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .blogholder {
    width: 80vw;
  }

  .blogcontentholder {
    padding-top: 10vh;
  }

  .BlogPublishedOn {
    font-size: 2.2vw;
    color: var(--text-color);
    padding-top: 1vh;
    width: 80vw;
  }

  .BlogHeading {
    font-size: 5vw;
    line-height: 3vh;
  }

  .BlogCategory {
    font-size: 2.6vw;
    width: 80vw;
  }

  .BlogImage {
    width: 80vw;
  }

  .BlogInfo {
    font-size: 3vw;
    color: var(--grey-color);
  }

  .SubHeading {
    font-size: 4vw;
    line-height: 3vh;
    width: 80vw;
  }

  .SubImage {
    width: 80vw;
  }

  .SubInfo {
    font-size: 3vw;
    line-height: 2.4vh;
    width: 80vw;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
