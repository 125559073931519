.maincontainer {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.firstsection {
  width: 80vw;
  margin-top: 10vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.heading {
  background: var(--gradient-color);
  color: transparent;
  background-clip: text;
  font-size: 4vw;
  text-align: center;
  margin-top: 10vh;
  font-weight: 700;
  font-family: "Garnett-Semibold";
}

.intro {
  text-align: center;
  color: #c7c7d6;
  margin-top: 3vh;
  font-weight: 2vw;
}

.inputdetailsholders {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 30vw;
  gap: 3vh;
  margin: 10vh 0;
}

.inputholder {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 28vw;
}

.label {
  font-size: 1.4vw;
  font-family: "Garnett-Light";
  color: var(--text-color);
}

.inputfield {
  width: 100%;
  border-radius: 10px;
  padding: 1vw;
  background: transparent;
  border: 1px solid var(--light-grey);
  color: var(--text-color);
}

.inputfield:focus {
  outline: none;
}

.selectfield {
  width: 100%;
  border-radius: 10px;
  padding: 1vw;
  background: transparent;
  border: 1px solid var(--light-grey);
  color: #c7c7d6;
}

.selectfield:focus {
  outline: none;
}

.radioholders {
  margin-top: 1vh;
}

.radiolabel {
  color: var(--text-color);
  font-family: "Garnett-Light";
}

.formerror {
  color: red;
  font-size: 0.8vw;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .heading {
    font-size: 8vw;
    width: 80vw;
  }
  .intro {
    font-size: 4vw;
  }

  .inputdetailsholders {
    width: 80vw;
    margin: 5vh 0;
  }

  .inputholder {
    width: 74vw;
  }

  .selectfield {
    width: 74vw;
    padding: 2vh 3vw;
    margin-top: 1vh;
  }

  .label {
    font-size: 4vw;
  }

  .inputfield {
    font-size: 4vw;
    padding: 3vw;
    margin-top: 1vh;
  }

  .formerror {
    font-size: 3vw;
  }
}
