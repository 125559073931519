.mainholder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5vh;
  width: 100vw;
  background: var(--dark-grey);
  padding: 2vh 0;
}

.topsection {
  width: 80vw;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 5vh;
}

.avairsenseicon {
  width: 12vw;
}

.socialshareicons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1vw;
  padding: 2vh 0;
}

.socialicons {
  width: 1.4vw;
}

.contactholder {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2vh;
}

.footerheadone {
  color: var(--text-color);
}

.footeremail {
  color: var(--text-color);
  font-size: 1vw;
}

.footeraddress {
  color: var(--text-color);
  line-height: 3vh;
  font-size: 0.8vw;
}

.footercontact {
  color: var(--text-color);
  line-height: 3vh;
  font-size: 0.8vw;
}

.emailholder {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2vh;
  flex-direction: column;
}

.emailinput {
  width: 20vw;
  background: transparent;
  border: none;
  color: var(--text-color);
  font-size: 1.2vw;
  border-bottom: 1px solid var(--text-color);
}

.bottomsection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80vw;
  border-top: 1px solid var(--purple-color);
  padding: 2vh 0;
}

.footertext {
  color: var(--text-color);
  font-size: 0.8vw;
}

.footlink {
  text-decoration: none;
  color: var(--text-color);
}

.emailform {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 2vh;
}

.gradientButtonDisabled {
  background: var(--disabled-color);
  color: var(--text-color);
}

.gradientButtonDisabled:hover {
  transform: none;
  cursor: not-allowed;
}

.emailinput:focus {
  outline: none;
}

.error {
  color: red;
  font-size: 1vw;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .topsection {
    gap: 10vw;
    flex-wrap: wrap;
  }

  .avairsenseicon {
    width: 20vw;
  }

  .socialshareicons {
    gap: 3vw;
  }

  .socialicons {
    width: 5vw;
  }

  .footerheadone {
    font-size: 4vw;
    font-weight: 300;
  }

  .footeremail {
    font-size: 3.2vw;
    font-weight: 200;
  }

  .footeraddress,
  .footercontact {
    font-size: 3vw;
    font-weight: 200;
  }

  .emailinput {
    width: 80vw;
    font-size: 4vw;
  }

  .gradientButton {
    font-size: 4vw;
    padding: 2vh 10vw;
    border-radius: 10px;
  }

  .gradientButtonDisabled {
    font-size: 4vw;
    padding: 2vh 10vw;
    border-radius: 10px;
    background: var(--disabled-color);
  }

  .bottomsection {
    flex-direction: column;
    gap: 3vh;
  }

  .footertext {
    font-size: 2.4vw;
    text-align: center;
    font-weight: 100;
    line-height: 2vh;
  }

  .error {
    font-size: 3vw;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
