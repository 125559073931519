.mainholder {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 140vh;
  width: 100vw;
  overflow-y: auto;
}

.maincontainer {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3vh;
  padding-top: 5vh;
  margin-top: 5vh;
}

.notallowedtext {
  font-size: 5vw;
  color: var(--text-color);
}

.textnotallowed {
  font-size: 4vw;
  color: var(--text-color);
}

.backgroundimage {
  position: absolute;
  top: 30vh;
  z-index: -1000;
  opacity: 0.6;
}

.redirectingtext {
  color: var(--text-color);
  font-size: 2vw;
  text-align: center;
  line-height: 6vh;
  margin-bottom: 10vh;
  font-family: "Garnett-Light";
  font-weight: 400;
}

.redirect {
  margin-top: 4vh;
  font-size: 1vw;
  font-family: "Garnett-Regular";
  font-weight: 400;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .mainholder {
    height: 100%;
    justify-content: flex-start;
  }

  .maincontainer {
    height: 100%;
    width: 80vw;
    text-align: center;
    justify-content: flex-start;
  }

  .notallowedtext {
    font-size: 14vw;
    margin-top: 10vh;
  }

  .textnotallowed {
    font-size: 10vw;
  }

  .redirectingtext {
    font-size: 5vw;
    width: 80vw;
    line-height: 3vh;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2vh;
    flex-direction: column;
    font-family: "Garnett-Light";
    font-weight: 300;
  }

  .redirect {
    font-size: 3.8vw;
    margin-bottom: 10vh;
  }

  .backgroundimage {
    width: 90vw;
    top: 40vh;
  }
}
