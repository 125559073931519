.maincontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 100000;
}

.closeicon {
  position: absolute;
  bottom: 35px;
  transform: scale(1.6);
  z-index: 10000000;
}

.navigatebeforeicon {
  position: absolute;
  left: -5vw;
  z-index: 10000000;
}

.navigateaftericon {
  position: absolute;
  right: -5vw;
  z-index: 10000000;
}

.navigateaftericon:hover,
.navigatebeforeicon:hover,
.closeicon:hover {
  cursor: pointer;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .closeicon {
    bottom: 0;
  }

  .navigatebeforeicon {
    position: absolute;
    bottom: 0;
    left: 35vw;
  }

  .navigateaftericon {
    position: absolute;
    bottom: 0;
    right: 35vw;
  }
}
