.mainholder {
  width: 100vw;
  height: 100vh;
  background: var(--background-color);
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.contentholder {
  width: 84vw;
  padding: 20vh 0;
}

.mainhead {
  color: transparent;
  background: var(--gradient-color);
  background-clip: text;
  display: inline-block;
  font-size: 3vw;
}

.secondpara {
  color: var(--grey-color);
  padding-top: 2vh;
}

.subhead {
  color: transparent;
  background: var(--gradient-color);
  background-clip: text;
  display: inline-block;
  padding-top: 4vh;
  font-size: 2vw;
}

.firstpara {
  font-size: 1.4vw;
  color: var(--grey-color);
  padding-top: 2vh;
  line-height: 3.4vh;
  text-align: justify;
}

.mailto {
  color: var(--blue-link-color);
  text-decoration: underline;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .contentholder {
    padding: 14vh 0;
  }

  .mainhead {
    font-size: 6vw;
  }

  .secondpara {
    font-size: 3vw;
  }

  .subhead {
    font-size: 4vw;
    line-height: 3vh;
  }

  .firstpara {
    font-size: 3vw;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
