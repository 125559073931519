.mainholder {
  width: 100vw;
  height: 100vh;
  background: var(--background-color);
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  overflow-x: hidden;
}

.maincontentholder {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10vw;
  height: 100vh;
  margin-top: 5vh;
}

.lefttextholder {
  width: 40vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.firstwhite {
  font-family: "Garnett-Bold";
  color: var(--dark-grey-color);
  font-size: 10vw;
}

.secondwhite {
  font-family: "Garnett-Bold";
  color: var(--white-smoke-color);
  font-size: 10vw;
  margin-top: -10vh;
}

.formholder {
  width: 50vw;
  height: 70vh;
  border: 1px solid var(--white-smoke-color);
  margin: 15vh 0;
  margin-right: -20vw;
  border-radius: 10px;
  background: var(--dark-grey-color);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.formcontainer {
  width: 30vw;
  height: 60vh;
  border-radius: 10px;
  background: var(--background-color);
  margin-left: 4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2vh;
}

.inputfieldholder {
  width: 16vw;
  background: transparent;
  border: none;
  border-bottom: 1px solid var(--orange-color);
  font-size: 1.2vw;
  padding-top: 2vh;
  color: var(--text-color);
  padding-bottom: 1vh;
}

.inputfieldholder::placeholder {
  color: var(--grey-color);
}

.inputfieldholder:focus {
  outline: none;
}

.textareafield {
  width: 16vw;
  background: transparent;
  border: none;
  border-bottom: 1px solid var(--orange-color);
  font-size: 1.3vw;
  padding-top: 2vh;
  color: var(--text-color);
  padding-bottom: 1vh;
}

.textareafield::placeholder {
  color: var(--grey-color);
}

.textareafield:focus {
  outline: none;
}

.error {
  font-size: 0.8vw;
  color: red;
  margin-top: 1vh;
}

.submitticket {
  margin-top: 3vh;
  color: white;
}

.submitticketdisabled {
  background: var(--disabled-color);
  color: var(--text-color);
}

.submitticketdisabled:hover {
  cursor: not-allowed;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .maincontentholder {
    flex-direction: column;
    height: fit-content;
    padding: 16vh 0;
    gap: 10vh;
  }

  .firstwhite {
    font-size: 18vw;
  }

  .secondwhite {
    font-size: 18vw;
    margin-top: -5vh;
  }

  .lefttextholder {
    width: 80vw;
    margin: 0;
    padding: 0;
  }

  .formholder {
    width: 80vw;
    margin: 0;
    margin-top: 5vh;
    align-items: center;
    justify-content: center;
  }

  .formcontainer {
    width: 70vw;
    margin: 0;
  }

  .inputfieldholder {
    width: 50vw;
    font-size: 5vw;
  }

  .textareafield {
    width: 50vw;
    font-size: 5vw;
  }

  .error {
    font-size: 3vw;
  }

  .formitself {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .submitticket {
    width: 50vw;
    margin-top: 2vh;
    font-size: 4vw;
  }

  .submitticketdisabled {
    width: 50vw;
    margin-top: 2vh;
    font-size: 4vw;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
