.mainholder {
  margin: 0px;
  padding: 0px;
  overflow-y: auto;
  width: 100vw;
  height: 100%;
  background: var(--background-color);
}

.sectionone {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5vh;
  margin-top: 5vh;
  width: 100vw;
  height: 100vh;
}

.bannerheading {
  font-size: 3vw;
  color: var(--main-text-color);
  text-align: center;
  font-family: "Garnett-Medium";
}

.gradienttext {
  background: var(--gradient-color);
  background-clip: text;
  color: transparent;
  font-family: "Garnett-Semibold";
}

.bannerimage {
  width: 50vw;
}

.mobilebannerimage {
  display: none;
}

.featuresholder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.featuresection {
  width: 86vw;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 14vw;
}

.mobilefeaturesection {
  width: 86vw;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 14vw;
}

.featureimage {
  width: 35vw;
}

.featuremobileimage {
  width: 20vw;
}

.featurecontentholder {
  width: 30vw;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2vh;
}

.featuresmallheading {
  font-size: 1.4vw;
  font-family: "Garnett-Light";
  color: var(--main-text-color);
}

.featuremainheading {
  font-size: 3vw;
  color: var(--main-text-color);
  font-family: "Garnett-Medium";
}

.featureexplain {
  color: var(--grey-color);
  font-size: 1.6vw;
  font-family: "Garnett-Light";
  font-weight: 300;
  line-height: 4vh;
}

.weatherresistanceicons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 3vw;
}

.weathericon {
  width: 4vw;
}

.alexaholder {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 3vw;
}

.assistanceimages {
  width: 10vw;
}

.madeinindiaimage {
  width: 8vw;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .bannerheading {
    font-size: 8vw;
    width: 80vw;
  }

  .madeinindiaimage {
    width: 24vw;
  }

  .bannerimage {
    display: none;
  }

  .mobilebannerimage {
    display: block;
    width: 80vw;
  }

  .sectionone {
    height: 100vh;
  }

  .featuresholder {
    gap: 5vh;
  }

  .featuresection {
    flex-direction: column;
    justify-content: center;
    height: 80vh;
  }

  .mobilefeaturesection {
    flex-direction: column-reverse;
    justify-content: center;
    height: 80vh;
  }

  .featureimage {
    width: 60vw;
  }

  .featurecontentholder {
    width: 80vw;
  }

  .featuresmallheading {
    font-size: 5vw;
  }

  .featuremainheading {
    font-size: 8vw;
  }

  .featureexplain {
    font-size: 5vw;
  }

  .weathericon {
    width: 14vw;
  }

  .featuremobileimage {
    width: 40vw;
  }

  .assistanceimages {
    width: 30vw;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .sectionone {
    height: 50vh;
  }

  .featuresection {
    height: 40vh;
  }

  .featureexplain {
    line-height: 2vh;
  }

  .mobilefeaturesection {
    height: 40vh;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .sectionone {
    height: 100vh;
  }

  .featuresection {
    height: 60vh;
  }

  .featureexplain {
    line-height: 3vh;
  }

  .mobilefeaturesection {
    height: 60vh;
  }
}
