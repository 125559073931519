@font-face {
  font-family: "Garnett-Black";
  src: local("GarnettBlack"), url("./assets/fonts/garnett/Garnett-Black.woff") format("truetype");
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: "Garnett-Bold";
  src: local("GarnettBold"), url("./assets/fonts/garnett/Garnett-Bold.woff") format("truetype");
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "Garnett-Light";
  src: local("GarnettLight"), url("./assets/fonts/garnett/Garnett-Light.woff") format("truetype");
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: "Garnett-Medium";
  src: local("GarnettMedium"), url("./assets/fonts/garnett/Garnett-Medium.woff") format("truetype");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Garnett-Regular";
  src: local("GarnettRegular") , url("./assets/fonts/garnett/Garnett-Regular.woff") format("truetype");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Garnett-Semibold";
  src: local("GarnettSemibold") ,url("./assets/fonts/garnett/Garnett-Semibold.woff") format("truetype");
  font-weight: 800;
  font-display: swap;
}



html, body {
  margin: 0;
  font-family: "Garnett-Black";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
}

body::-webkit-scrollbar,
html::-webkit-scrollbar {
  display: none;
  width: 0 !important
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  html, body{
    overscroll-behavior: none;
}}
