.maincontainer {
  width: 100vw;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}

.sectionone {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0vw;
  width: 90vw;
  padding: 5vh 5vw;
  margin-top: 10vh;
}

.cameraimage {
  width: 42vw;
}

.ctaholdersmobile {
  display: none;
}

.leftsectionone {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 5vh;
  width: 50vw;
}

.senseheading {
  font-size: 4vw;
  font-family: "Garnett-Medium";
  color: var(--white-color);
}

.intropara {
  color: var(--white-color);
  font-size: 2.4vw;
  font-family: "Garnett-Medium";
  line-height: 6vh;
}

.gradienttext {
  color: transparent;
  background: var(--gradient-color);
  background-clip: text;
}

.ctaholders {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 3vw;
}

.buynow {
  color: white;
}

.buyonamazon {
  width: 12vw;
}

.sectiontwo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5vh 5vw;
}

.sectionheading {
  color: var(--white-color);
  font-size: 2.6vw;
  font-family: "Garnett-Semibold";
}

.reviewsliders {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 3vw;
  padding-top: 5vh;
}

.reviewsholder {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2vw;
  transition: transform 0.5s;
}

.leftreview,
.rightreview {
  background: var(--grey-one);
  padding: 2vw;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2vh;
  flex: 1.2;
}

.middlereview {
  background: var(--grey-one);
  padding: 2vw;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2vh;
  flex: 1.2;
}

.topratingholder {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.username {
  color: var(--white-color);
  font-size: 1vw;
}

.usernamemiddle {
  color: var(--white-color);
  font-size: 1.6vw;
}

.navicon {
  display: none;
}

.review {
  color: var(--grey-eight);
  font-size: 0.9vw;
  padding: 0 2vw;
  text-align: center;
  line-height: 2.2vh;
}

.reviewmiddle {
  color: var(--grey-eight);
  font-size: 1.2vw;
  text-align: center;
  line-height: 2.8vh;
}

.reviewdotsholder {
  display: none;
  align-items: center;
  justify-content: center;
  gap: 1vw;
  width: 100%;
  margin-top: 5vh;
}

.dotactive {
  background: var(--gradient-color);
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.dot {
  background: var(--grey-five);
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.protectsection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2vh;
  padding: 5vh 5vw;
}

.gradientprotectheading {
  color: transparent;
  background: var(--gradient-color);
  background-clip: text;
  font-family: "Garnett-Semibold";
  font-size: 2.6vw;
  line-height: 4vh;
}

.protectholders {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 2vw;
  padding: 5vh 0;
}

.protectindividual {
  width: 18vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1vh;
  flex-direction: column;
}

.protectimage {
  width: 10vw;
}

.protectheading {
  color: var(--white-color);
  font-size: 1.6vw;
  text-align: center;
  font-family: "Garnett-Semibold";
}

.protectinfo {
  color: #c7c7d6;
  text-align: center;
  font-size: 1vw;
  padding: 0 2vw;
  line-height: 2vh;
}

.tricoloursections {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0;
  margin-top: 5vh;
}

.bluesectionholder {
  background: var(--dark-blue);
  width: 100vw;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 5vw;
  padding-top: 10vh;
}

.mobileimageholder {
  width: 40vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.emergencyimage {
  width: 24vw;
}

.sectionthree {
  padding: 5vh 0vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3vh;
}

.gradientheading {
  color: transparent;
  background: var(--gradient-color);
  background-clip: text;
  font-family: "Garnett-Regular";
  font-size: 1.8vw;
  line-height: 4vh;
}

.featuresholder {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 5vw;
  row-gap: 5vw;
  padding: 5vh 0;
}

.individualfeature {
  width: 40vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2vh;
  margin-bottom: 15vh;
}

.newfeatureicon {
  width: 8vw;
}

.hrline {
  background: var(--grey-seven);
  width: 60%;
  height: 0.5px;
}

.newfeatureheading {
  color: var(--white-color);
  font-size: 2vw;
  font-family: "Garnett-Medium";
}

.newfeaturecontent {
  color: #c7c7d6;
  font-size: 1.6vw;
  line-height: 4vh;
  align-items: center;
  text-align: center;
  padding: 0 2vw;
  font-family: "Garnett-Medium";
}

.sectionfour {
  padding: 10vh 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3vh;
  position: relative;
}

.avairsensetext {
  font-size: 12vw;
  color: var(--dark-grey);
  font-family: "Garnett-Bold";
  padding-top: 10vh;
}

.cameratwoimage {
  width: 12vw;
  position: absolute;
  top: 8vh;
}

.thefourlineholder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3vh;
}

.firstpara {
  font-size: 2vw;
  color: var(--white-color);
  font-family: "Garnett-Regular";
  line-height: 2vh;
}

.gradientspan {
  background: var(--gradient-color);
  background-clip: text;
  color: transparent;
  font-family: "Garnett-Semibold";
}

.sectionfive {
  padding: 5vh 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2vh;
}

.hardwarefeature {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto auto;
  column-gap: 3vw;
  row-gap: 3vw;
  padding: 5vh 0 0 0;
}

.hardwarefeatureholder {
  padding: 2vw;
  background: var(--grey-one);
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2vh;
  width: 18vw;
}

.hardwarefeatureholderlight {
  padding: 2vw;
  background: var(--grey-two);
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2vh;
  width: 18vw;
}

.hardwarefeatureicon {
  width: 5vw;
}

.hardwarefeatureheading {
  color: var(--white-color);
  font-size: 2.4vw;
  font-family: "Garnett-Medium";
  font-weight: 200;
  margin-right: 2vw;
  line-height: 5vh;
}

.hardwarefeaturecontent {
  color: var(--grey-eight);
  font-size: 1.4vw;
  line-height: 3vh;
  margin-right: 2vw;
}

.sectionsix {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5vh 5vw;
}

.actionholder {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5vw;
  padding: 5vh 10vw;
}

.leftsideoptions {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto;
  align-items: center;
  justify-content: center;
  text-align: center;
  column-gap: 2vw;
  row-gap: 2vw;
  width: 30vw;
}

.leftsideoptions > *:last-child {
  grid-column: 1 / 3; /* Spanning the last item across both columns */
  justify-self: center; /* Centering the last item within its column */
}

.featureiconholder {
  width: 70%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: var(--dark-grey-color);
  padding: 1vw;
  gap: 1vh;
  border-radius: 10px;
}

.featureiconholder_active {
  width: 70%;
  height: 10vh;
  display: flex;
  align-items: center;
  gap: 1vh;
  justify-content: center;
  flex-direction: column;
  background: var(--gradient-color);
  padding: 1vw;
  border-radius: 10%;
}

.featureicon {
  width: 2.5vw;
}

.featureholder {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  gap: 1vw;
}

.featureholder:hover {
  cursor: pointer;
  transform: scale(1.01);
}

.featureheading {
  font-size: 1vw;
  color: var(--disabled-color);
  font-family: "Garnett-Regular";
  font-weight: 400;
}

.featureheading_active {
  font-size: 1vw;
  color: var(--main-text-color);
  font-family: "Garnett-Regular";
  font-weight: 400;
}

.rightimageholder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 30vw;
  gap: 4vh;
}

.alertimage {
  width: 26vw;
}

.downloadiconholder {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2vw;
}

.downloadiconholdermobile {
  display: none;
}

.downloadicon {
  width: 12vw;
}

.sectionseven {
  padding: 5vh 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.subimage {
  width: 100vw;
  margin: 0;
  padding: 0;
  margin-bottom: -12vh;
}

.subimagemobile {
  display: none;
}

.bluesection {
  background: var(--dark-blue);
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bluetext {
  color: var(--white-color);
  text-align: center;
  padding: 5vh 0;
}

.tvcsection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2vh;
  padding: 5vh 5vw;
}

.tvcholders {
  display: flex;
  width: 90vw;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow-x: scroll;
  gap: 5vw;
}

.bigimage {
  width: 30vw;
}

.twosmallimageholder {
  width: 30vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 5vh;
}

.twosmallimage {
  width: 30vw;
}

.bigimage:hover,
.twosmallimage:hover {
  cursor: pointer;
}

.sectioneight {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5vh;
  padding: 10vh 5vw;
}

.thumbnailholders {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5vw;
  padding: 3vh 0;
}

.leftthumb,
.rightthumb {
  background: var(--background-color);
  height: 340px;
  width: 220px;
  border-radius: 10px;
}

.middlethumb {
  height: 400px;
  width: 220px;
  transition: all 0.8s ease-in-out;
}

.leftthumb:hover,
.rightthumb:hover,
.middlethumb:hover {
  cursor: pointer;
}

.middlethumbactive {
  background: var(--background-color);
  opacity: 1;
  width: 600px;
  height: 420px;
  transform: scale(1.5);
  transition: all 0.8s ease-in-out;
}

.sectionnine {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3vh;
  padding: 5vh 5vw;
}

.faqheading {
  font-size: 1.6vw;
  color: var(--white-color);
}

.sectioneightcontentholder {
  width: 70vw;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10vw;
  background: var(--grey-one);
  padding: 5vh 5vw;
  border-radius: 10px;
}

.gradienthead {
  color: transparent;
  background: var(--gradient-color);
  background-clip: text;
  font-weight: 800;
  font-family: "Garnett-Semibold";
  font-size: 1.6vw;
  line-height: 3vh;
}

.askusholder {
  width: 35vw;
}

.formholder {
  padding-top: 4vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 1vh;
}

.forminputfield {
  background: transparent;
  color: var(--main-text-color);
  border: none;
  width: 100%;
  font-size: 1vw;
  border-bottom: 1px solid var(--main-text-color);
}

.forminputfield:focus {
  outline: none;
}

.error {
  color: red;
  font-size: 0.8vw;
  padding-top: 1vh;
}

.formsubmit {
  margin-top: 4vh;
}

.formsubmitDisabled {
  margin-top: 4vh;
  background: var(--disabled-color);
}

.formsubmitDisabled:hover {
  transform: none;
  cursor: not-allowed;
}

.accordionsholder {
  padding-top: 4vh;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 2vw;
  row-gap: 2vw;
}

.accordion {
  background: transparent !important;
  border: 1px solid var(--main-text-color);
  width: 20vw;
  border-radius: 16px !important;
}

.accordiontype {
  color: var(--main-text-color);
}

.accordionicon {
  color: var(--main-text-color);
}

.sectionten {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10vh 0;
  gap: 3vh;
}

.backedbytext {
  color: transparent;
  background: var(--gradient-color);
  background-clip: text;
  font-size: 2vw;
}

.headingnine {
  color: white;
  font-size: 2.6vw;
}

.logoholder {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5vw;
}

.backedYlogo {
  width: 20vw;
}

.link {
  color: var(--text-color);
  text-decoration: underline;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .sectionone {
    margin-top: 10vh;
    flex-direction: column;
  }

  .cameraimage {
    width: 90vw;
  }

  .ctaholders {
    display: none;
  }

  .ctaholdersmobile {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3vw;
  }

  .leftsectionone {
    width: 90vw;
    gap: 2vh;
  }

  .senseheading {
    font-size: 8vw;
  }

  .intropara {
    font-size: 5vw;
    line-height: 4vh;
    font-family: "Garnett-Light";
    font-weight: 300;
  }

  .buyonamazon {
    width: 42vw;
    margin-top: 0.8vh;
  }

  .sectionheading {
    font-size: 7vw;
    text-align: center;
  }

  .reviewsholder {
    justify-content: flex-start;
    width: 90vw;
    overflow-x: auto;
    flex-wrap: nowrap;
  }

  .topratingholder {
    width: 70vw;
  }

  .leftreview,
  .rightreview {
    width: 80vw;
    padding: 4vw;
  }

  .middlereview {
    padding: 5vw;
  }

  .navicon {
    width: 3vw;
  }

  .usernamemiddle,
  .username {
    font-size: 4vw;
  }

  .reviewmiddle,
  .review {
    font-size: 4vw;
  }

  .gradientprotectheading {
    font-size: 6vw;
  }

  .protectholders {
    flex-direction: column;
    gap: 6vh;
    padding: 5vh 5vw;
  }

  .bluesectionholder {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 0vh;
  }

  .bluesectionholder:nth-child(2) {
    flex-direction: column;
  }

  .mobileimageholder {
    width: 90vw;
  }

  .individualfeature {
    width: 90vw;
    margin-bottom: 5vh;
  }

  .emergencyimage {
    width: 80vw;
  }

  .protectindividual {
    width: 90vw;
    gap: 2vh;
  }

  .protectimage {
    width: 50vw;
  }

  .protectheading {
    font-size: 5vw;
  }

  .protectinfo {
    font-size: 4vw;
    line-height: 3vh;
  }

  .sectionthree {
    gap: 2vh;
  }

  .gradientheading {
    font-size: 4.2vw;
    text-align: center;
  }

  .featuresholder {
    row-gap: 5vh;
  }

  .individualfeature {
    width: 90vw;
  }

  .newfeatureicon {
    width: 20vw;
  }

  .sectionfour {
    padding: 5vh 5vw;
  }

  .newfeatureheading {
    font-size: 5vw;
  }

  .newfeaturecontent {
    font-size: 3.8vw;
    line-height: 3vh;
  }

  .cameratwoimage {
    top: 6vh;
    width: 30vw;
  }

  .thefourlineholder {
    margin-top: 10vh;
  }

  .firstpara {
    font-size: 4vw;
    line-height: 1vh;
  }

  .sectionfive {
    gap: 1vh;
  }

  .hardwarefeature {
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto;
  }

  .hardwarefeatureholder {
    width: 38vw;
    gap: 1vh;
  }

  .hardwarefeatureholderlight {
    width: 38vw;
    gap: 1vh;
  }

  .hardwarefeatureicon {
    width: 18vw;
  }

  .hardwarefeatureheading {
    font-size: 5vw;
    line-height: 3vh;
    font-family: "Garnett-Medium";
  }

  .hardwarefeaturecontent {
    font-size: 3vw;
    line-height: 2vh;
  }

  .actionholder {
    flex-direction: column;
    gap: 5vh;
  }

  .leftsideoptions {
    width: 90vw;
  }

  .alertimage {
    width: 90vw;
  }

  .downloadiconholder {
    display: none;
  }

  .downloadiconholdermobile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2vw;
  }

  .downloadicon {
    width: 70vw;
  }

  .leftsideoptions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 3vw;
    overflow-x: auto;
  }

  .featureiconholder {
    width: 30vw;
  }

  .featureiconholder_active {
    width: 30vw;
  }

  .featureicon {
    width: 10vw;
  }

  .featureheading {
    font-size: 3vw;
  }

  .featureheading_active {
    font-size: 3vw;
  }

  .subimage {
    display: none;
  }

  .subimagemobile {
    display: block;
    width: 100vw;
  }

  .bluetext {
    font-size: 4vw;
    padding: 3vh 10vw;
    line-height: 3vh;
  }

  .middlethumb {
    width: 35vw;
    height: 240px;
  }

  .middlethumbactive {
    width: 380;
    height: 240;
    transform: scale(0.9);
  }

  .thumbnailholders {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3vw;
    padding: 5vh 0;
  }

  .leftthumb,
  .rightthumb {
    width: 20vw;
    height: 160px;
  }

  .sectioneightcontentholder {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-start;
    width: 80vw;
  }

  .askusholder {
    width: 70vw;
  }

  .forminputfield {
    font-size: 4vw;
  }

  .formsubmit {
    font-size: 4vw;
  }

  .submitbuttonholder {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .accordionsholder {
    row-gap: 3vh;
  }

  .accordion {
    width: 70vw;
  }

  .backedYlogo {
    width: 90vw;
  }

  .faqheading {
    font-size: 4vw;
  }

  .gradienthead {
    font-size: 4vw;
  }

  .tvcholders {
    flex-direction: column;
    gap: 2vh;
  }

  .bigimage {
    width: 90vw;
  }

  .twosmallimage {
    width: 90vw;
  }

  .twosmallimageholder {
    gap: 2vh;
  }
}
