.mainholder {
  margin: 0px;
  padding: 0px;
  overflow-y: auto;
  width: 100vw;
  height: 100vh;
  background: var(--background-color);
}

.sectionone {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10vh;
  height: 80vh;
}

.comingsoon {
  font-size: 4vw;
  color: transparent;
  background: var(--gradient-color);
  background-clip: text;
  line-height: 10vh;
}

.blogholder {
  width: 86vw;
  display: grid;
  column-gap: 7vw;
  row-gap: 6vh;
  grid-template-columns: auto auto auto;
  padding: 0 0 10vh 0;
}

.individualblog {
  width: 24vw;
}

.linktag {
  text-decoration: none;
}

.blogheading {
  color: var(--text-color);
  text-decoration: none;
  padding-top: 2vh;
  line-height: 3.4vh;
  font-size: 1.4vw;
  font-family: "Garnett-Semibold";
  font-weight: 800;
}

.bloginfo {
  color: var(--light-grey);
  padding-top: 1.6vh;
  line-height: 3vh;
  font-family: "Garnett-Medium";
}

.blogfeatureimage {
  width: 24vw;
}

.blogcover {
  width: 100vw;
}

.blogmobilecover {
  display: none;
}

.mainblogcontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.linkholders {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2vw;
  width: 86vw;
  padding: 10vh 0;
}

.linktocat {
  color: var(--grey-color);
  font-size: 1.2vw;
}

.linktocatactive {
  background: var(--gradient-color);
  background-clip: text;
  font-size: 1.2vw;
  color: transparent;
}

.linktocat:hover {
  cursor: pointer;
}

.nothingtoshow {
  color: var(--text-color);
}

.featureimageholder {
  position: relative;
}

.blogcategory {
  position: absolute;
  top: 2px;
  right: 3px;
  font-size: 0.6vw;
  background: var(--background-color);
  color: var(--text-color);
  padding: 10px 5px;
  border-radius: 10px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .mainholder {
    overflow-x: hidden;
  }

  .blogcover {
    display: none;
  }

  .blogmobilecover {
    display: block;
    width: 100vw;
  }

  .blogfeatureimage {
    width: 80vw;
  }

  .blogcategory {
    font-size: 3vw;
    padding: 1vh 2vw;
  }

  .linkholders {
    padding: 2vh 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5vw;
  }

  .linktocat {
    font-size: 4vw;
    font-family: "Garnett-Semibold";
    color: var(--shop-grey-color);
  }

  .linktocatactive {
    font-size: 4vw;
    font-family: "Garnett-Semibold";
  }

  .blogholder {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 3vh 0;
    gap: 5vh;
  }

  .individualblog {
    width: 80vw;
  }

  .blogheading {
    font-size: 5vw;
    padding-top: 1vh;
  }

  .bloginfo {
    font-size: 4vw;
    padding-top: 1vh;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
