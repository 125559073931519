body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: var(--background-color);
  overflow-x: hidden;
  overscroll-behavior: none;
}

body::-webkit-scrollbar,
html::-webkit-scrollbar {
  display: none;
  width: 0 !important;
}

html {
  overscroll-behavior: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
button {
  margin: 0;
  padding: 0;
  line-height: 100%;
  font-family: "Garnett-Light";
}

button {
  background: var(--gradient-color);
  border: none;
  color: var(--text-color);
  border-radius: 10px;
  padding: 2vh 3vw;
  font-size: 1vw;
  font-family: "Garnett-Regular";
}

button:hover {
  cursor: pointer;
  transform: scale(1.02);
}

// For mobile screens
@media only screen and (max-width: 600px) {
  button {
    width: 40vw;
    font-size: 3vw;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  button {
    width: 20vw;
  }
}

.preloaderholder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4vh;
  height: 100vh;
  width: 100vw;
}

.lottie {
  width: 4vw;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  html,
  body {
    overscroll-behavior: none;
  }

  body::-webkit-scrollbar,
  html::-webkit-scrollbar {
    display: none;
    width: 0 !important;
  }

  .preloaderholder {
    height: 80vh;
  }

  .lottie {
    width: 14vw;
  }
}

ul,
li,
ol {
  font-weight: 100;
  font-family: "Garnett-Regular";
}

span {
  font-family: "Garnett-Semibold";
}

:root {
  --background-color: #121212;
  --first-heading: 6vw;
  --second-heading: 4.5vw;
  --third-heading: 3vw;
  --fourth-heading: 2vw;
  --first-paragraph: 3vw;
  --second-paragraph: 2.5vw;
  --third-paragraph: 1.5vw;
  --grey-color: #c7c7d6;
  --dark-grey-color: #333333;
  --black-color: #000000;
  --main-text-color: #ffffff;
  --text-color: #ffffff;
  --purple-color: #8a2487;
  --white-smoke-color: #cccccc;
  --light-grey: #575757;
  --dark-grey: #1c1c1c;
  --disabled-color: #8c8c8c;
  --shop-grey-color: #9c9c9c;
  --blue-link-color: blue;
  --blue-color: #22a6fa;
  --dark-blue: #2969ff;
  --nova-orange: #cd7f32;
  --orange-color: #f27121;
  --aurora-yellow: #d4af37;
  --fuschia-color: #e94057;
  --white-color: #ffffff;
  --grey-two: #2c2c2c;
  --grey-one: #1c1c1c;
  --grey-four: #4c4c4c;
  --grey-five: #5c5c5c;
  --grey-seven: #7c7c7c;
  --grey-eight: #8c8c8c;
  --dark-grey: #252525;
  --gradient-color: linear-gradient(
    50.19deg,
    #8a2387 0%,
    #e94057 50%,
    #f27121 100%
  );
}
