.mainmodal {
  width: 30vw;
  height: fit-content;
  border-radius: 10px;
  background: var(--grey-one);
  padding: 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  backface-visibility: hidden;
  transition: transform 1s;
  transform-style: preserve-3d;
  animation: modal-animate 0.4s linear 1;
}

@keyframes modal-animate {
  from {
    top: 100%;
  }
  to {
    top: 50%;
  }
}

.mainmodalflipped {
  width: 30vw;
  height: fit-content;
  border-radius: 10px;
  background: var(--grey-one);
  padding: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: rotateY(180deg) translate(50%, -50%);
  transition: 1s all ease-in-out;
}

.mainmodal:focus {
  border: none;
  outline: none;
}

.topclosebar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.modalcontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url("../../assets/icons/frame.svg");
  background-position: bottom -20vh right 0px;
  background-repeat: no-repeat;
  gap: 2vh;
}

.heading {
  color: white;
  font-size: 2vw;
  line-height: 5vh;
}

.hello {
  color: var(--white-color);
  font-family: "Garnett-Semibold";
  font-size: 3vw;
  text-align: center;
}

.intro {
  color: var(--grey-seven);
  font-size: 1vw;
  text-align: center;
  line-height: 2.2vh;
}

.illustrationicon {
  width: 24vw;
}

.rightholder {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 2vh;
  margin-top: 3vh;
}

.formlabel {
  color: white;
  font-family: "Garnett-Medium";
}

.forminput {
  width: 100%;
  padding: 0 0 1vh 0;
  background: transparent;
  color: white;
  border: none;
  border-bottom: 1px solid var(--grey-seven);
  font-size: 1.2vw;
}

.forminput:focus {
  outline: none;
}

.closeicon:hover {
  cursor: pointer;
}

.formerror {
  color: red;
  font-size: 0.8vw;
}

.contentholder {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3vh;
  background: var(--grey-one);
  flex-direction: column;
  transform: rotateY(180deg);
}

.namehead {
  color: white;
  font-size: 1.8vw;
}

.mainpara {
  color: #c7c7d6;
  font-weight: 400;
  font-family: "Garnett-medium";
  font-size: 1.2vw;
  padding: 5vh 5vw;
  text-align: center;
}

.strikedamount {
  text-decoration: line-through;
}

.gradienttext {
  background: var(--gradient-color);
  background-clip: text;
  color: transparent;
  font-style: italic;
}

.submitbutton {
  background: var(--dark-blue);
  width: 100%;
}

.senseimage {
  width: 4vw;
  opacity: 0.4;
}

.copybutton {
  width: 100%;
  background: var(--dark-blue);
}

.cardholder {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.codestyle {
  color: #fae422;
  font-size: 1.8vw;
  border-radius: 5px;
  padding: 1vh;
  font-family: "Garnett-Semibold";
  position: absolute;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .modalcontainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5vh;
  }

  .heading {
    font-size: 4.8vw;
    line-height: 4vh;
  }

  .illustrationicon {
    display: none;
  }

  .rightholder {
    width: 100%;
  }

  .forminput {
    font-size: 4vw;
    padding: 0 0 2vh 0;
    border-radius: none;
  }

  .formerror {
    font-size: 3vw;
  }

  .namehead {
    font-size: 8vw;
  }

  .topclosebar {
    padding-bottom: 2vh;
  }

  .mainmodal {
    padding: 5vw;
    width: 80vw;
  }

  .mainmodalflipped {
    padding: 5vw;
    width: 80vw;
  }

  .hello {
    font-size: 8vw;
  }

  .intro {
    font-size: 4vw;
  }

  .copybutton {
    display: inline-block;
    width: 100% !important;
  }

  .mainpara {
    font-size: 3vw;
    line-height: 2vh;
    padding: 2vh 0;
  }

  .codestyle {
    font-size: 5vw;
  }

  .senseimage {
    width: 16vw;
  }

  .gradienttext {
    font-size: 4vw;
  }
}
