.maincontainer {
  width: 100vw;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  z-index: 1000000;
}

.topbar {
  width: 100vw;
  background: var(--fuschia-color);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40vw;
  padding: 1vh 0vw;
  z-index: 1000000;
  overflow: hidden;
}

.linksholder {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8vw;
  z-index: 1000000;
}

.deliveringtext,
.deliveringtexttwo {
  color: var(--white-color);
  font-size: 1vw;
}

.deliveringtext,
.deliveringtexttwo {
  /* animation properties */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);

  -moz-animation: my-animation 15s linear infinite;
  -webkit-animation: my-animation 15s linear infinite;
  animation: my-animation 15s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.navholder {
  background: var(--grey-two);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2vh 5vw;
  width: 90vw;
  z-index: 1000000;
}

.logo {
  width: 5vw;
}

.logo:hover {
  cursor: pointer;
}

.navlink {
  padding: 0px;
  margin: 0px;
  color: white;
  font-weight: 500;
  text-decoration: none;
  font-family: "Garnett-Semibold";
  font-size: 1.2vw;
  line-height: 3vh;
}

.navlink:hover {
  background: var(--gradient-color);
  --webkit-background-clip: text;
  --webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  cursor: pointer;
  transform: scale(1.1);
  line-height: 3vh;
  font-family: "Garnett-Semibold";
}

.navlink_active {
  padding: 0px;
  margin: 0px;
  background: var(--gradient-color);
  --webkit-background-clip: text;
  --webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-weight: 400;
  text-decoration: none;
  font-family: "Garnett-Semibold";
  font-size: 1.2vw;
  line-height: 2vh;
}

.menuicon {
  width: 8vw;
  display: none;
}

.mobilelinksholderinitial {
  display: none;
}

.mobilelinksholderHidden {
  display: none;
}

.mobilelinksholderShown {
  display: flex;
  position: absolute;
  width: 100vw;
  height: fit-content;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4vh;
  top: 10vh;
  left: 0;
  z-index: 1000;
  background-color: var(--grey-two);
  padding: 2vh 0;
}

.resourceiconholder {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.6vw;
  position: relative;
  padding: 0;
  margin: 0;
}

.subnavlink {
  color: var(--text-color);
  font-size: 1.2vw;
  font-family: "Garnett-Regular";
  font-weight: 400;
}

.subnavlink:hover {
  cursor: pointer;
}

.rightarrowactive {
  width: 0.6vw;
  height: auto;
  padding: 0;
  margin: 0;
  transform: rotate(90deg);
}

.rightarrow {
  width: 0.6vw;
  height: auto;
  padding: 0;
  margin: 0;
  transition: all 0.2s ease-in-out;
}

.submenulinksholder {
  position: absolute;
  top: 0vh;
  left: 74vw;
  opacity: 0;
  background-color: var(--grey-two);
  padding: 2vh 2vw;
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2vh;
  transition: all 0.25s ease-in-out;
  z-index: -1000;
}

.submenulinksholderactive {
  position: absolute;
  top: 12vh;
  left: 74vw;
  opacity: 1;
  background-color: var(--grey-two);
  padding: 2vh 2vw;
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2vh;
  z-index: 1000;
  transition: all 0.25s ease-in-out;
}

.mobileresourcelinksholder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4vh;
}

.iconsholder {
  display: none;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .navholder {
    padding: 1vh 5vw;
  }

  .deliveringtext {
    font-size: 3vw;
    width: 80vw;
    text-align: center;
  }

  .deliveringtexttwo {
    display: none;
  }

  .linksholder {
    display: none;
  }

  .logo {
    width: 14vw;
  }

  .menuicon {
    display: block;
  }

  .navlink_active {
    font-size: 4vw;
    font-family: "Garnett-Black";
    font-weight: 100;
  }

  .navlink {
    font-size: 4vw;
    font-family: "Garnett-Black";
    font-weight: 100;
  }

  .resourceiconholder {
    gap: 3vw;
  }

  .subnavlink {
    font-size: 4vw;
    color: white;
    font-weight: 500;
    font-family: "Garnett-Medium";
  }

  .mobilerightarrow {
    width: 2vw;
    height: auto;
    margin: 0;
    padding: 0;
    transform: rotate(90deg);
    transition: all 0.2s ease-in-out;
  }

  .mobilerightarrowactive {
    width: 2vw;
    height: auto;
    margin: 0;
    padding: 0;
  }

  .mobilelinksholderinitial {
    display: none;
  }

  .mobilelinksholderShown {
    animation-name: movethenavdown;
    animation-duration: 0.4s;
    animation-timing-function: ease-in;
    animation-direction: alternate-reverse;
  }

  .mobilelinksholderHidden {
    display: flex;
    position: absolute;
    bottom: 200vh;
    left: 0;
    width: 100vw;
    height: fit-content;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4vh;
    z-index: 100;
    background-color: #333333b3;
    backdrop-filter: blur(20px);
    padding: 2vh 0;
    border-radius: 10px;
    animation-name: movethenavup;
    animation-duration: 0.4s;
    animation-timing-function: ease-in;
    animation-direction: alternate-reverse;
    animation-fill-mode: forwards;
  }

  @keyframes movethenavup {
    from {
      top: -40vh;
    }
    to {
      top: 8vh;
    }
  }

  @keyframes movethenavdown {
    from {
      top: 8vh;
    }
    to {
      top: -40vh;
    }
  }

  .iconsholder {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 3vw;
  }

  .shopicon {
    padding: none;
    margin-top: 3px;
  }
}
